import {
  GET_ALL_MODEL_VERSION_DETAILS,
  MODEL_VERSION_IS_LOADING,
  UPDATE_MODEL_VERSION_USER_SAVE_STATUS,
  MODEL_VERSION_MANAGEMENT_ERROR,
  SET_MODEL_VERSION_CURRENT_PAGE,
  SET_MODEL_VERSION_PAGE_FILTER,
  SET_MODEL_VERSION_DETAILS,
  SET_MODEL_VERSION_DETAILS_DUPLICATE,
  SET_MODEL_VERSION_CHANGE,
  MODEL_VERSION_THRESHOLDS_MANAGEMENT_ERROR,
  MODEL_VERSION_THRESHOLDS_IS_LOADING,
  GET_ALL_MODEL_VERSION_DETAILS_THRESHOLDS,
  GET_ALL_MODEL_VERSION_DETAILS_SEQUENCES_VERSION,
  SET_THRESHOLDS_PAGE_FILTER,
  MODEL_VERSION_GLOBAL_SETTINGS_MANAGEMENT_ERROR,
  MODEL_VERSION_GLOBAL_SETTINGS_IS_LOADING,
  GET_ALL_MODEL_VERSION_GLOBAL_SETTINGS,
  SET_MODEL_VERSION_THRESHOLDS_DETAILS,
  SET_MODEL_VERSION_THRESHOLDS_CHANGE,
  UPDATE_MODEL_VERSION_THRESHOLDS_SAVE_STATUS,
  UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SAVE_STATUS,
  SET_MODEL_VERSION_GLOBAL_SETTINGS_DETAILS,
  SET_MODEL_VERSION_GLOBAL_SETTINGS_CHANGE,
  UPDATE_MODEL_VERSION_THRESHOLDS_SHOW_SCREEN,
  UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SHOW_SCREEN,
  MODEL_VERSION_UNITS_MANAGEMENT_ERROR,
  MODEL_VERSION_UNITS_IS_LOADING,
  UPDATE_MODEL_VERSION_UNITS_SAVE_STATUS,
  GET_ALL_MODEL_VERSION_UNITS,
  SET_MODEL_VERSION_UNITS_CHANGE,
  UPDATE_MODEL_VERSION_UNITS_SHOW_SCREEN,
  MODEL_VERSION_MODEL_COMPONENTS_POSITIONS_MODEL,
  MODEL_VERSION_SERVICE_PLANS_MANAGEMENT_ERROR,
  MODEL_VERSION_SERVICE_PLANS_IS_LOADING,
  GET_ALL_MODEL_VERSION_SERVICE_PLANS,
  SET_MODEL_VERSION_SERVICE_PLANS_DETAILS,
  SET_MODEL_VERSION_SERVICE_PLANS_CHANGE,
  UPDATE_MODEL_VERSION_SERVICE_PLANS_SAVE_STATUS,
  UPDATE_MODEL_VERSION_SERVICE_PLANS_SHOW_SCREEN,
  MODEL_VERSION_UNIT_GLOBAL_SETTINGS_MANAGEMENT_ERROR,
  MODEL_VERSION_UNIT_GLOBAL_SETTINGS_IS_LOADING,
  GET_ALL_MODEL_VERSION_UNIT_GLOBAL_SETTINGS,
  SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_DETAILS,
  SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_CHANGE,
  UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SAVE_STATUS,
  UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SHOW_SCREEN,
  UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SHOW_SCREEN,
  MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_MANAGEMENT_ERROR,
  MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_IS_LOADING,
  GET_ALL_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS,
  SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_DETAILS,
  SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_CHANGE,
  UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SAVE_STATUS
} from '../constants/index';
import { initialModelVersionState } from './initialState';

const modelVersionReducer = (state = initialModelVersionState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_MODEL_VERSION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          ...data
        }
      };
    }

    case SET_MODEL_VERSION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          currentPage
        }
      };
    }

    case SET_MODEL_VERSION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          filter
        }
      };
    }

    case UPDATE_MODEL_VERSION_USER_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          ...data
        }
      };
    }

    case MODEL_VERSION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          isLoading: isLoading
        }
      };
    }

    case MODEL_VERSION_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          ...data
        }
      };
    }

    case SET_MODEL_VERSION_DETAILS: {
      let { modelVersionDetails } = payload;

      let modelVersionData = modelVersionDetails.length > 0 ? modelVersionDetails[0] : state.modelVersionManager.selectedModelVersion;
      modelVersionData.GlobalSettings = state.modelVersionManager.selectedModelVersion.GlobalSettings;
      modelVersionData.Thresholds = state.modelVersionManager.selectedModelVersion.Thresholds;
      modelVersionData.ModelVersionUnit = state.modelVersionManager.selectedModelVersion.ModelVersionUnit;
      modelVersionData.UnitGlobalSettings = state.modelVersionManager.selectedModelVersion.UnitGlobalSettings;
      modelVersionData.PeriodicSummarySettings = state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings;
      modelVersionData.ModelComponentPositions = state.modelVersionManager.selectedModelVersion.ModelComponentPositions;
      modelVersionData.ServicePlans = state.modelVersionManager.selectedModelVersion.ServicePlans;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: modelVersionData
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case SET_MODEL_VERSION_CHANGE: {
      let { selectedModelVersion } = payload;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: selectedModelVersion
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case SET_MODEL_VERSION_DETAILS_DUPLICATE: {
      let { modelVersionDetails, organisationHirarchies } = payload;

      let modelVersionData = modelVersionDetails.length > 0 ? modelVersionDetails[0] : state.modelVersionManager.selectedModelVersion;
      modelVersionData.GlobalSettings = state.modelVersionManager.selectedModelVersion.GlobalSettings;
      modelVersionData.Thresholds = state.modelVersionManager.selectedModelVersion.Thresholds;
      modelVersionData.SequenceVersions = state.modelVersionManager.selectedModelVersion.SequenceVersions;
      modelVersionData.ModelVersionUnit = state.modelVersionManager.selectedModelVersion.ModelVersionUnit;
      modelVersionData.ModelComponentPositions = state.modelVersionManager.selectedModelVersion.ModelComponentPositions;
      modelVersionData.ServicePlans = state.modelVersionManager.selectedModelVersion.ServicePlans;
      modelVersionData.UnitGlobalSettings = state.modelVersionManager.selectedModelVersion.UnitGlobalSettings;
      modelVersionData.PeriodicSummarySettings = state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings;
      modelVersionData.OrganisationHirarchies = organisationHirarchies || [];
      modelVersionData.ModelVersionId = 0;
      modelVersionData.UpdatedBy = '';
      modelVersionData.GuidIdentifier = '';
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: modelVersionData
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case GET_ALL_MODEL_VERSION_DETAILS_THRESHOLDS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              ...data
            }
          }
        }
      };
    }
    case GET_ALL_MODEL_VERSION_DETAILS_SEQUENCES_VERSION: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ...data
          }
        }
      };
    }

    case SET_THRESHOLDS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              filter
            }
          }
        }
      };
    }

    case MODEL_VERSION_THRESHOLDS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              isLoading: isLoading
            }
          }
        }
      };
    }

    case MODEL_VERSION_THRESHOLDS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              ...data
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_THRESHOLDS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              ...data
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_THRESHOLDS_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_THRESHOLDS_DETAILS: {
      let { modelVersionDetailThreshold } = payload;

      let modelVersionThresholdsData =
        modelVersionDetailThreshold.length > 0
          ? modelVersionDetailThreshold[0]
          : state.modelVersionManager.selectedModelVersion.Thresholds.SelectedThreshold;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              SelectedThreshold: {
                ...state.modelVersionManager.selectedModelVersion.Thresholds.SelectedThreshold,
                ...modelVersionThresholdsData
              }
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_THRESHOLDS_CHANGE: {
      let { SelectedThreshold } = payload;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            Thresholds: {
              ...state.modelVersionManager.selectedModelVersion.Thresholds,
              SelectedThreshold: {
                ...state.modelVersionManager.selectedModelVersion.Thresholds.SelectedThreshold,
                ...SelectedThreshold
              }
            }
          }
        }
      };
    }

    case GET_ALL_MODEL_VERSION_GLOBAL_SETTINGS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            GlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.GlobalSettings,
              ...data
            }
          }
        }
      };
    }

    case MODEL_VERSION_GLOBAL_SETTINGS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            GlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.GlobalSettings,
              isLoading: isLoading
            }
          }
        }
      };
    }

    case MODEL_VERSION_GLOBAL_SETTINGS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            GlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.GlobalSettings,
              ...data
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            GlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.GlobalSettings,
              ...data
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_GLOBAL_SETTINGS_DETAILS: {
      let { modelVersionDetailsGlobalSettings } = payload;

      let modelVersionGlobalSettingsData =
        modelVersionDetailsGlobalSettings.length > 0
          ? modelVersionDetailsGlobalSettings[0]
          : state.modelVersionManager.selectedModelVersion.GlobalSettings.SelectedGlobalSetting;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            GlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.GlobalSettings,
              SelectedGlobalSetting: {
                ...state.modelVersionManager.selectedModelVersion.GlobalSettings.SelectedGlobalSetting,
                ...modelVersionGlobalSettingsData
              }
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_GLOBAL_SETTINGS_CHANGE: {
      let { SelectedGlobalSetting } = payload;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            GlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.GlobalSettings,
              SelectedGlobalSetting: {
                ...state.modelVersionManager.selectedModelVersion.GlobalSettings.SelectedGlobalSetting,
                ...SelectedGlobalSetting
              }
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            GlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.GlobalSettings,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    case MODEL_VERSION_UNITS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ModelVersionUnit: {
              ...state.modelVersionManager.selectedModelVersion.ModelVersionUnit,
              ...data
            }
          }
        }
      };
    }

    case MODEL_VERSION_UNITS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ModelVersionUnit: {
              ...state.modelVersionManager.selectedModelVersion.ModelVersionUnit,
              isLoading: isLoading
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_UNITS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ModelVersionUnit: {
              ...state.modelVersionManager.selectedModelVersion.ModelVersionUnit,
              ...data
            }
          }
        }
      };
    }

    case GET_ALL_MODEL_VERSION_UNITS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ModelVersionUnit: {
              ...state.modelVersionManager.selectedModelVersion.ModelVersionUnit,
              ...data
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_UNITS_CHANGE: {
      let { SelectedModelVersionUnits } = payload;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ModelVersionUnit: {
              ...state.modelVersionManager.selectedModelVersion.ModelVersionUnit,
              SelectedModelVersionUnits
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_UNITS_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ModelVersionUnit: {
              ...state.modelVersionManager.selectedModelVersion.ModelVersionUnit,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    case MODEL_VERSION_MODEL_COMPONENTS_POSITIONS_MODEL: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ...data
          }
        }
      };
    }

    case GET_ALL_MODEL_VERSION_SERVICE_PLANS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ServicePlans: {
              ...state.modelVersionManager.selectedModelVersion.ServicePlans,
              ...data
            }
          }
        }
      };
    }

    case MODEL_VERSION_SERVICE_PLANS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ServicePlans: {
              ...state.modelVersionManager.selectedModelVersion.ServicePlans,
              isLoading: isLoading
            }
          }
        }
      };
    }

    case MODEL_VERSION_SERVICE_PLANS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ServicePlans: {
              ...state.modelVersionManager.selectedModelVersion.ServicePlans,
              ...data
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_SERVICE_PLANS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ServicePlans: {
              ...state.modelVersionManager.selectedModelVersion.ServicePlans,
              ...data
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_SERVICE_PLANS_DETAILS: {
      let { modelVersionDetailsServicePlans } = payload;

      let modelVersionServicePlansData =
        modelVersionDetailsServicePlans.length > 0
          ? modelVersionDetailsServicePlans[0]
          : state.modelVersionManager.selectedModelVersion.ServicePlans.SelectedServicePlan;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ServicePlans: {
              ...state.modelVersionManager.selectedModelVersion.ServicePlans,
              SelectedServicePlan: {
                ...state.modelVersionManager.selectedModelVersion.ServicePlans.SelectedServicePlan,
                ...modelVersionServicePlansData
              }
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_SERVICE_PLANS_CHANGE: {
      let { SelectedServicePlan } = payload;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ServicePlans: {
              ...state.modelVersionManager.selectedModelVersion.ServicePlans,
              SelectedServicePlan: {
                ...state.modelVersionManager.selectedModelVersion.ServicePlans.SelectedServicePlan,
                ...SelectedServicePlan
              }
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_SERVICE_PLANS_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            ServicePlans: {
              ...state.modelVersionManager.selectedModelVersion.ServicePlans,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    //Unit Global settings
    case GET_ALL_MODEL_VERSION_UNIT_GLOBAL_SETTINGS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            UnitGlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings,
              ...data
            }
          }
        }
      };
    }

    case MODEL_VERSION_UNIT_GLOBAL_SETTINGS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            UnitGlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings,
              isLoading: isLoading
            }
          }
        }
      };
    }

    case MODEL_VERSION_UNIT_GLOBAL_SETTINGS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            UnitGlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings,
              ...data
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            UnitGlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings,
              ...data
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_DETAILS: {
      let { unitGlobalSettings } = payload;

      let modelVersionUnitGlobalSettingsData =
        unitGlobalSettings.length > 0
          ? unitGlobalSettings[0]
          : state.modelVersionManager.selectedModelVersion.UnitGlobalSettings.SelectedUnitGlobalSetting;
      let isMinMaxDisabled = false;
      if (modelVersionUnitGlobalSettingsData && isNaN(modelVersionUnitGlobalSettingsData.DefaultValue)) {
        isMinMaxDisabled = true;
      }
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            UnitGlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings,
              SelectedUnitGlobalSetting: {
                ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings.SelectedUnitGlobalSetting,
                ...modelVersionUnitGlobalSettingsData,
                IsMinMaxDisabled: isMinMaxDisabled
              }
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_CHANGE: {
      let { SelectedUnitGlobalSetting } = payload;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            UnitGlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings,
              SelectedUnitGlobalSetting: {
                ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings.SelectedUnitGlobalSetting,
                ...SelectedUnitGlobalSetting
              }
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            UnitGlobalSettings: {
              ...state.modelVersionManager.selectedModelVersion.UnitGlobalSettings,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    case MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            PeriodicSummarySettings: {
              ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings,
              ...data
            }
          }
        }
      };
    }

    case MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            PeriodicSummarySettings: {
              ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings,
              isLoading: isLoading
            }
          }
        }
      };
    }

    case GET_ALL_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            PeriodicSummarySettings: {
              ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings,
              ...data
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_DETAILS: {
      let { periodicSummarySettings } = payload;

      let modelVersionPeriodicSummarySettingsData =
        periodicSummarySettings.length > 0
          ? periodicSummarySettings[0]
          : state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings.SelectedPeriodicSummarySetting;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            PeriodicSummarySettings: {
              ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings,
              SelectedPeriodicSummarySetting: {
                ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings.SelectedPeriodicSummarySetting,
                ...modelVersionPeriodicSummarySettingsData
              }
            }
          }
        }
      };
    }

    case SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_CHANGE: {
      let { SelectedPeriodicSummarySetting } = payload;

      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            PeriodicSummarySettings: {
              ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings,
              SelectedPeriodicSummarySetting: {
                ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings.SelectedPeriodicSummarySetting,
                ...SelectedPeriodicSummarySetting
              }
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            PeriodicSummarySettings: {
              ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings,
              ...data
            }
          }
        }
      };
    }

    case UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        modelVersionManager: {
          ...state.modelVersionManager,
          selectedModelVersion: {
            ...state.modelVersionManager.selectedModelVersion,
            PeriodicSummarySettings: {
              ...state.modelVersionManager.selectedModelVersion.PeriodicSummarySettings,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default modelVersionReducer;
