import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionConfiguration.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialModelVersionState } from '../../reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ModelVersionGlobalSettingsContainer from '../ModelVersionGlobalSettings/ModelVersionGlobalSettingsContainer';
import ModelVersionThresholdsContainer from '../ModelVersionThresholds/ModelVersionThresholdsContainer';
import ModelVersionServicePlanContainer from '../ModelVersionServicePlan/ModelVersionServicePlanContainer';
import ModelVersionUnitsContainer from '../ModelVersionUnits/ModelVersionUnitsContainer';
import UnitGlobalSettingsContainer from '../UnitGlobalSettings/UnitGlobalSettingsContainer';
import PeriodicSummarySettingsContainer from '../PeriodicSummarySettings/PeriodicSummarySettingsContainer';

import Banner from '../Banner/Banner';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { WidgetVisibility } from '../../utils/widgetManager';
import uuid from 'uuid';
import Button from 'react-bootstrap/Button';
import { MODEL_MANAGEMENT, UPDATE_MODEL_VERSION_UNIT_SHADOW } from '../../constants/logs';
import { APP_VERSION } from '../../constants';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';

const ModelVersionConfiguration = ({ modelVersionManager, describeModelVersion, userProfileWidgets, getModelComponentPositions, intl }) => {
  let { ModelVersionId, ModelId } = useParams();
  const [globalSettingsOpen, setGlobalSettingsOpen] = useState(false);
  const [thresholdsOpen, setThresholdsOpen] = useState(false);
  const [modelVersionUnitsOpen, setModelVersionUnitsOpen] = useState(false);
  const [servicePlanOpen, setServicePlanOpen] = useState(false);
  const [unitGlobalSettingsOpen, setUnitGlobalSettingsOpen] = useState(false);
  const [periodicSummarySettingsOpen, setPeriodicSummarySettingsOpen] = useState(false);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    describeModelVersion(ModelVersionId);
  }, [describeModelVersion, ModelVersionId]);

  useEffect(() => {
    getModelComponentPositions(ModelId, null, true);
  }, [getModelComponentPositions, ModelId]);

  const [shadowUpdateState, setShadowUpdateState] = useState({ isLoading: false, isOpSuccessful: true, showBanner: false, message: '' });

  let heading = intl.formatMessage({ id: 'modelVersion.configureModelVersion', defaultMessage: 'Model Version Configuration' });

  let messageId = (modelVersionManager && modelVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelName = (modelVersionManager.selectedModelVersion ? modelVersionManager.selectedModelVersion.ModelName : '') || '';

  const onUpdateShadowClick = async () => {
    setShadowUpdateState({ isLoading: true, isOpSuccessful: true, showBanner: false, message: '' });

    let payLoadData = { modelVersionId: ModelVersionId, modelId: ModelId, updatedBy: Cookies.get(`userid-${envName}`) || '' };

    let log = {
      Timestamp: moment().unix(),
      Username: Cookies.get(`username-${envName}`) || '',
      SessionId: '',
      UserId: Cookies.get(`userid-${envName}`) || '',
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || '',
      UnitId: 0,
      Function: MODEL_MANAGEMENT,
      Action: UPDATE_MODEL_VERSION_UNIT_SHADOW,
      Payload: JSON.stringify(payLoadData)
    };

    let respData = await fetch(`${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/updateunitshadow`, {
      method: 'post',
      body: JSON.stringify({ ...payLoadData, log: log }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get(`access-${envName}`),
        widgetcode: 'MODELVERSIONUNITSHADOWUPDATE',
        accessid: Cookies.get(`selectedprofileid-${envName}`)
      }
    });

    //Reading from stream and converting to JSON
    let retData = await respData.json();

    if (!respData.ok) {
      let errMessage = intl.formatMessage({
        id: 'modelVersionShadow.updationFailed',
        defaultMessage: 'Shadow update Failed'
      });

      setShadowUpdateState({ isLoading: false, isOpSuccessful: false, showBanner: true, message: errMessage });
      hideBanner();
      return;
    }

    if (retData && retData.errors) {
      let errMessage = intl.formatMessage({
        id: 'modelVersionShadow.updationFailed',
        defaultMessage: 'Shadow update Failed'
      });

      setShadowUpdateState({ isLoading: false, isOpSuccessful: false, showBanner: true, message: errMessage });
      hideBanner();
      console.log(retData.errors);
      return;
    }

    if (!retData.errors) {
      let message = intl.formatMessage({
        id: 'modelVersionShadow.updatedSuccessfully',
        defaultMessage: 'Shadow updated successfully'
      });
      setShadowUpdateState({ isLoading: false, isOpSuccessful: true, showBanner: true, message: message });
      hideBanner();
    }
  };
  const hideBanner = () => {
    setTimeout(() => {
      setShadowUpdateState({ ...shadowUpdateState, showBanner: false });
    }, 4000);
  };

  return (
    <div className={s.modelversionConfiguration}>
      {(modelVersionManager.isLoading || shadowUpdateState.isLoading) && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={modelVersionManager.showBanner}
        status={modelVersionManager.isOpSuccessful}
        successText={messageText}
      />

      <Banner
        key={uuid()}
        failureText={shadowUpdateState.message}
        showBanner={shadowUpdateState.showBanner}
        status={shadowUpdateState.isOpSuccessful}
        successText={shadowUpdateState.message}
      />

      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelversionHeader}>
            <Row>
              <Col xs={12} md={6}>
                <Link className={s.backLink} to={`/admin-controls/model-configuration/${ModelId}/`}>
                  &lt; &nbsp;
                  <FormattedMessage id="modelVersion.backToModels" defaultMessage="BACK TO MODELS" />
                </Link>
                <h3 data-unittest="headingLabel">
                  {heading} {modelName} : {modelVersionManager.selectedModelVersion.Name}
                </h3>
              </Col>
              <Col xs={12} md={6} className={s.alignRight} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONUNITSHADOWUPDATE')}>
                <Button
                  variant="outline-secondary"
                  className={s.btnUpdateShadow}
                  onClick={onUpdateShadowClick}
                  noValidate
                  data-unittest="saveDefault"
                >
                  <FormattedMessage id="modelVersionManagement.updateUnitShadow" defaultMessage="UPDATE UNITS SHADOW" />
                </Button>
              </Col>
            </Row>
          </div>
          <div className={s.modelversionContent}>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONGLOBALSETTINGS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={globalSettingsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setGlobalSettingsOpen(!globalSettingsOpen)}
                      aria-controls="global-settings-section"
                      aria-expanded={globalSettingsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.globalSettings" defaultMessage="Global Settings" />
                    </h4>
                    <span className={s.circle}>{modelVersionManager.selectedModelVersion.GlobalSettings.GlobalSettingsList.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={globalSettingsOpen}>
                <div className={s.innerComponent} id="global-settings-section">
                  <ModelVersionGlobalSettingsContainer
                    ReadOnly={false}
                    ModelVersionId={ModelVersionId}
                    userProfileWidgets={userProfileWidgets}
                  />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'UNITGLOBALSETTINGS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={unitGlobalSettingsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setUnitGlobalSettingsOpen(!unitGlobalSettingsOpen)}
                      aria-controls="unit-global-setting-section"
                      aria-expanded={unitGlobalSettingsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.unitGlobalSettings" defaultMessage="Unit Global Settings" />
                    </h4>
                    <span className={s.circle}>
                      {modelVersionManager.selectedModelVersion.UnitGlobalSettings.UnitGlobalSettingsList.length}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={unitGlobalSettingsOpen}>
                <div className={s.innerComponent} id="unit-global-settings-section">
                  <UnitGlobalSettingsContainer ModelVersionId={ModelVersionId} userProfileWidgets={userProfileWidgets} />
                </div>
              </Collapse>
            </div>

            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONTHRESHOLDS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={thresholdsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setThresholdsOpen(!thresholdsOpen)}
                      aria-controls="thresholds-section"
                      aria-expanded={thresholdsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.threasholds" defaultMessage="Thresholds" />
                    </h4>
                    <span className={s.circle}>{modelVersionManager.selectedModelVersion.Thresholds.ThresholdsList.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={thresholdsOpen}>
                <div className={s.innerComponent} id="thresholds-section">
                  <ModelVersionThresholdsContainer
                    ReadOnly={false}
                    ModelVersionId={ModelVersionId}
                    userProfileWidgets={userProfileWidgets}
                  />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSION_UNIT_VIEW')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={modelVersionUnitsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setModelVersionUnitsOpen(!modelVersionUnitsOpen)}
                      aria-controls="units-section"
                      aria-expanded={modelVersionUnitsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.units.manageUnitVersion" defaultMessage="Manage Unit Versions" />
                    </h4>
                    <span className={s.circle}>
                      {modelVersionManager.selectedModelVersion.ModelVersionUnit.ModelVersionUnitsList.length}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={modelVersionUnitsOpen}>
                <div className={s.innerComponent} id="units-section">
                  <ModelVersionUnitsContainer userProfileWidgets={userProfileWidgets} />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'PERIODICSUMMARYSETTINGS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={periodicSummarySettingsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setPeriodicSummarySettingsOpen(!periodicSummarySettingsOpen)}
                      aria-controls="periodic-summary-setting-section"
                      aria-expanded={periodicSummarySettingsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.periodicSummarySettings" defaultMessage="Periodic SummarySettings" />
                    </h4>
                    <span className={s.circle}>
                      {modelVersionManager.selectedModelVersion.PeriodicSummarySettings.PeriodicSummarySettingsList.length}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={periodicSummarySettingsOpen}>
                <div className={s.innerComponent} id="periodic-summary-setting-section">
                  <PeriodicSummarySettingsContainer ModelVersionId={ModelVersionId} userProfileWidgets={userProfileWidgets} />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONSERVICEPLANS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={servicePlanOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setServicePlanOpen(!servicePlanOpen)}
                      aria-controls="service-plan-section"
                      aria-expanded={servicePlanOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.servicePlans" defaultMessage="Service Plans" />
                    </h4>
                    <span className={s.circle}>{modelVersionManager.selectedModelVersion.ServicePlans.ServicePlansList.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={servicePlanOpen}>
                <div className={s.innerComponent} id="service-plan-section">
                  <ModelVersionServicePlanContainer
                    ReadOnly={false}
                    ModelVersionId={ModelVersionId}
                    userProfileWidgets={userProfileWidgets}
                  />
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelVersionConfiguration.defaultProps = {
  modelVersionManager: {
    ...initialModelVersionState.modelVersionManager
  }
};

ModelVersionConfiguration.propTypes = {
  modelVersionManager: PropTypes.object.isRequired,
  describeModelVersion: PropTypes.func.isRequired,
  getModelComponentPositions: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionConfiguration);
