import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './PeriodicSummarySettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelVersionState } from '../../reducers/initialState';
import PeriodicSummarySettingListTable from './PeriodicSummarySettingsListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const PeriodicSummarySettingsList = ({
  periodicSummarySetting,
  intl,
  ModelVersionId,
  ReadOnly,
  userProfileWidgets,
  getPeriodicSummarySettings,
  savePeriodicSummarySetting,
  describePeriodicSummarySetting,
  changePeriodicSummarySetting,
  setPeriodicSummarySettingShowScreenStatus,
  currentTimezone
}) => {
  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  let initialRecords = periodicSummarySetting.PeriodicSummarySettingsList.slice(0, initialLimit);

  useEffect(() => {
    getPeriodicSummarySettings(offset, limit, filter, ModelVersionId);
  }, [getPeriodicSummarySettings, offset, limit, filter, ModelVersionId]);

  const [currentEditId, setCurrentEditId] = useState(-1);
  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [delPeriodicSummarySettingId, setPeriodicSummarySettingId] = useState(0);
  const [searchText, setSearchText] = useState('');

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const localSettingLength = localSettings.length;
  const periodicSummarySettingLength = periodicSummarySetting.PeriodicSummarySettingsList.length;
  const addNewRef = useRef(null);

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(periodicSummarySetting.PeriodicSummarySettingsList);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setPeriodicSummarySettingShowScreenStatus(status);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = periodicSummarySetting.PeriodicSummarySettingsList.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const setAddHandler = () => {
    setPeriodicSummarySettingShowScreenStatus(true);
    setCurrentEditId(0);
    window.scrollTo(0, addNewRef.current.offsetTop);
  };

  const setEditHandler = periodicSummarySettingId => {
    setCurrentEditId(periodicSummarySettingId);
    if (periodicSummarySettingId === -1) {
      changePeriodicSummarySetting({
        SelectedPeriodicSummarySetting: {
          ...initialModelVersionState.modelVersionManager.selectedModelVersion.PeriodicSummarySettings.SelectedPeriodicSummarySetting,
          ModelVersionId: ModelVersionId
        }
      });
      setPeriodicSummarySettingShowScreenStatus(false);
    } else {
      setPeriodicSummarySettingShowScreenStatus(true);
    }
  };

  const filterData = () => {
    let filteredList = periodicSummarySetting.PeriodicSummarySettingsList;
    if (isFilter) {
      filteredList = periodicSummarySetting.PeriodicSummarySettingsList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getSettings = () => {
    let periodicSummarySetting = [];
    if (showAll) {
      periodicSummarySetting = filterData();
    } else {
      if (isFilter) {
        periodicSummarySetting = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        periodicSummarySetting = initialRecords;
      }
    }
    return periodicSummarySetting.sort(compareValues('Name'));
  };

  const removePeriodicSummarySettingClick = periodicSummarySettingId => {
    setActive(true);
    setPeriodicSummarySettingId(periodicSummarySettingId);
  };
  const DeletePeriodicSummarySetting = () => {
    let saveData = periodicSummarySetting.SelectedPeriodicSummarySetting;
    saveData.MarkAsDeleted = true;
    saveData.ModelVersionId = ModelVersionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.PeriodicSummarySettingId = delPeriodicSummarySettingId;
    savePeriodicSummarySetting(saveData, 'PERIODICSUMMARYSETTINGSDELETE');
  };

  const handleClose = () => {
    setActive(false);
    setPeriodicSummarySettingId(0);
  };

  const handleConfirm = () => {
    DeletePeriodicSummarySetting();
    handleClose();
  };

  let messageId = (periodicSummarySetting && periodicSummarySetting.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'PERIODICSUMMARYSETTINGSEDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'PERIODICSUMMARYSETTINGSDELETE');

  const dialogTitle = intl.formatMessage({
    id: 'modelVersion.periodicSummarySettingsConfirmDelete',
    defaultMessage: 'Are you sure you want to delete this periodic summary setting?'
  });

  return (
    <div className={s.periodicSummarySettings}>
      {periodicSummarySetting.isLoading && <LoadingSpinner />}
      {periodicSummarySetting.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={periodicSummarySetting.showBanner}
          status={periodicSummarySetting.isOpSuccessfull}
          successText={messageText}
        />
      )}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={periodicSummarySetting.showAddEditScreen}
              // initialText={filter}
              placeHolderTextId="modelVersion.search"
              data-unittest="searchPeriodicSummarySettings"
            />
          </Col>
          <Col sm={6} lg={8}>
            {!ReadOnly ? (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'PERIODICSUMMARYSETTINGSADD')}
                onClick={setAddHandler}
                disabled={periodicSummarySetting.showAddEditScreen}
                data-unittest="addPeriodicSummarySettingButton"
              >
                + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
              </Button>
            ) : (
              ''
            )}
          </Col>
        </Row>

        <div>
          <PeriodicSummarySettingListTable
            setEditHandler={setEditHandler}
            ModelVersionId={ModelVersionId}
            selectedPeriodicSummarySettingId={currentEditId}
            savePeriodicSummarySetting={savePeriodicSummarySetting}
            periodicSummarySetting={periodicSummarySetting}
            describePeriodicSummarySetting={describePeriodicSummarySetting}
            changePeriodicSummarySetting={changePeriodicSummarySetting}
            UpdateAllowed={isUpdateAllowed}
            DeleteAllowed={isDeleteAllowed}
            periodicSummarySettingsData={getSettings()}
            disableMode={periodicSummarySetting.showAddEditScreen}
            disableModeHandler={disableModeHandler}
            addNewRef={addNewRef}
            ReadOnly={ReadOnly}
            removePeriodicSummarySetting={removePeriodicSummarySettingClick}
            currentTimezone={currentTimezone}
          />
        </div>
        {((!isFilter && periodicSummarySettingLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? periodicSummarySettingLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

PeriodicSummarySettingsList.defaultProps = {
  ...initialModelVersionState
};

PeriodicSummarySettingsList.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  getPeriodicSummarySettings: PropTypes.func.isRequired,
  savePeriodicSummarySetting: PropTypes.func.isRequired,
  describePeriodicSummarySetting: PropTypes.func.isRequired,
  changePeriodicSummarySetting: PropTypes.func.isRequired,
  setPeriodicSummarySettingShowScreenStatus: PropTypes.func.isRequired
};

export default injectIntl(PeriodicSummarySettingsList);
