import Cookies from 'js-cookie';

import {
  GET_ALL_PROFILE_DETAILS,
  PROFILE_DETAIL,
  PROFILE_IS_LOADING,
  PROFILE_ERROR,
  UPDATE_SAVE_STATUS,
  SET_SELECTED_PROFILE,
  UPDATE_ERROR_STATUS,
  SET_SELECTED_PROFILE_WIDGET
} from '../constants/index';
import { apiAction, logEntry } from '../utils/index';
import { PROFILE_MANAGEMENT, CREATE_PROFILE, UPDATE_PROFILE } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setProfileError = value => ({
  type: PROFILE_ERROR,
  payload: { value }
});

var params = {
  orgId: Cookies.get(`selectedorganisationid-${envName}`) || 0
};

export const getProfileThunk = widgetCode => dispatch => {
  params.orgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(setProfileLoadingStatus(true));
  dispatch(getProfile(widgetCode));
};

export const getProfile = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/profile`,
    data: params,
    method: 'GET',
    onSuccess: setAllProfileList,
    onFailure: () => setProfileError(true),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const getSelectedProfileWidgetsThunk = ProfileId => dispatch => {
  dispatch(getSelectedProfileWidgets(ProfileId));
};

export const getSelectedProfileWidgets = ProfileId =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/profile/profilewidgets`,
    data: {
      Id: ProfileId,
      orgId: Cookies.get(`selectedorganisationid-${envName}`) || 0
    },
    method: 'GET',
    onLoad: status => setProfileLoadingStatus(status),
    onSuccess: setSelectedProfileWidgetsThunk,
    onFailure: error => setProfileFailedThunk(error),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setSelectedProfileWidgetsThunk = ({ profile }) => dispatch => {
  dispatch(setSelectedProfileWidgets(profile));
};

export const setSelectedProfileWidgets = profile => ({
  type: SET_SELECTED_PROFILE_WIDGET,
  payload: { profile }
});

export const setAllProfileListThunk = profile => dispatch => {
  dispatch(setAllProfileList(profile));
};

export const setAllProfileList = ({ profile }) => ({
  type: GET_ALL_PROFILE_DETAILS,
  payload: { profile }
});

export const setSelectedProfile = profileId => ({
  type: SET_SELECTED_PROFILE,
  payload: { profileId }
});

export const getProfileThunkById = (id, widgetCode) => dispatch => {
  dispatch(getProfileById(id, widgetCode));
};
export const setSelectedProfileThunk = profile => dispatch => {
  dispatch(setProfile(profile));
};

export const getProfileById = (id, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/profile/` + id,
    data: {
      id: id,
      orgId: Cookies.get(`selectedorganisationid-${envName}`) || 0
    },
    method: 'GET',
    onSuccess: setProfile,
    onFailure: () => setProfileError(true),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setProfile = profile => ({
  type: PROFILE_DETAIL,
  payload: { profile }
});

export const postProfileThunk = (profile, widgetCode) => dispatch => {
  let actionName = profile.ProfileId === 0 ? CREATE_PROFILE : UPDATE_PROFILE;
  let log = logEntry(PROFILE_MANAGEMENT, actionName, profile);
  dispatch(setProfileLoadingStatus(true));
  dispatch(saveProfile(profile, log, widgetCode));
};

export const saveProfile = (profile, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/profile/save`,
    method: 'POST',
    onSuccess: message => setProfileSuccessThunk(message),
    onFailure: error => setProfileFailedThunk(error),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode,
    data: {
      profile,
      log
    }
  });

export const setProfileSuccessThunk = message => dispatch => {
  dispatch(setProfileSuccessStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setProfileSuccessStatus('', false, true));
  }, 2500);
};

export const setProfileFailedThunk = error => dispatch => {
  let message =
    (error &&
      error.response &&
      error.response.data &&
      error.response.data.errorMessage &&
      error.response.data.errorMessage.replace('[400]=>', '')) ||
    '';
  dispatch(setProfileErrorStatus(message, true));
};

export const setProfileSuccessStatus = (message, showBanner, redirect) => ({
  type: UPDATE_SAVE_STATUS,
  payload: { message, showBanner, redirect }
});

export const setProfileErrorStatus = (message, showBanner) => ({
  type: UPDATE_ERROR_STATUS,
  payload: { message, showBanner }
});

export const setProfileLoadingStatus = status => ({
  type: PROFILE_IS_LOADING,
  payload: { status }
});
