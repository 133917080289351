import React from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import closeBtn from '../../../assets/error-red-filled.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import s from './FileList.module.scss';

const FileList = ({ files, colSize, onRemoveFile, showDelete = true }) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const downloadFile = async (fileGuid, fileName) => {
    try {
      let respData = await fetch(`${process.env.REACT_APP_API_ENDPOINT_ADMIN1}/management/general/filedownload`, {
        method: 'post',
        body: JSON.stringify({
          userId: Cookies.get(`userid-${envName}`) || '',
          orgId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
          fileGuid: fileGuid
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get(`access-${envName}`),
          widgetcode: 'GENERALACCESS',
          accessid: Cookies.get(`selectedprofileid-${envName}`) || ''
        }
      });
      //Reading from stream and converting to JSON
      let retData = await respData.json();

      if (retData.statusCode === 200 && retData.presignedUrl) {
        delete axios.defaults.headers.common['Authorization'];
        const axios_instance = axios.create();
        const response = await axios_instance.get(retData.presignedUrl, {
          headers: {},
          responseType: 'blob' // Ensures the response is treated as a binary file
        });
        const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName); // Suggested file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the Blob URL
        window.URL.revokeObjectURL(urlBlob);
      } else if (retData.statusCode === 200 && !retData.presignedUrl) {
        console.log('Missing presignedUrl ');
      } else {
        throw retData;
      }
    } catch (err) {
      let messageCode = (err && err.errorMessage) || null;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
      console.log('Exception ', err);
    }
  };

  return (
    files &&
    files.length > 0 &&
    files.map(file => {
      return (
        <Row className={s.rowFile} sm={12} md={10} lg={colSize ?? 6} key={`${file.FileId}`}>
          <Col xs={10} className={`${s.textMediumMessageAlignLeft} ${s.colFile}`}>
            <a className={s.link} onClick={() => downloadFile(file.FileId, file.Name)} href={`javascript:void(0);`}>
              {file.Name}
            </a>
          </Col>
          <Col className={s.colFile} xs={2}>
            {!file.isUploading && !file.uploadCompleted && onRemoveFile && showDelete && (
              <img
                src={closeBtn}
                alt="icon"
                className={s.removeBtn}
                onClick={() => {
                  onRemoveFile(file.FileId);
                }}
              />
            )}
          </Col>
        </Row>
      );
    })
  );
};
export default FileList;
