import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './PeriodicSummarySettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import PeriodicSummarySettings from './PeriodicSummarySettings';

const PeriodicSummarySettingListTable = ({
  setEditHandler,
  ModelVersionId,
  selectedPeriodicSummarySettingId,
  savePeriodicSummarySetting,
  periodicSummarySetting,
  describePeriodicSummarySetting,
  changePeriodicSummarySetting,
  periodicSummarySettingsData,
  UpdateAllowed,
  DeleteAllowed,
  ReadOnly,
  disableMode,
  disableModeHandler,
  addNewRef,
  removePeriodicSummarySetting,
  currentTimezone
}) => {
  const fetchDataHandler = periodicSummarySettingId => {
    setEditHandler(periodicSummarySettingId);
    disableModeHandler(true);
    describePeriodicSummarySetting(periodicSummarySettingId);
  };

  const deleteDataHandler = periodicSummarySettingId => {
    removePeriodicSummarySetting(periodicSummarySettingId);
  };

  const cancelHandler = () => {
    setEditHandler(-1);
    disableModeHandler(false);
  };

  const editContent = (
    <PeriodicSummarySettings
      PeriodicSummarySettingId={selectedPeriodicSummarySettingId}
      ModelVersionId={ModelVersionId}
      savePeriodicSummarySetting={savePeriodicSummarySetting}
      describePeriodicSummarySetting={describePeriodicSummarySetting}
      periodicSummarySetting={periodicSummarySetting}
      changePeriodicSummarySetting={changePeriodicSummarySetting}
      cancelHandler={cancelHandler}
      currentTimezone={currentTimezone}
      setEditHandler={setEditHandler}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{editContent}</td>
    </tr>
  );

  let newRowClass = periodicSummarySetting.showAddEditScreen && selectedPeriodicSummarySettingId === 0 ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="periodicSummarySettings.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="periodicSummarySettings.code" defaultMessage="Code" />
            </th>
            <th>
              <FormattedMessage id="periodicSummarySettings.expression" defaultMessage="Expression Value" />
            </th>
            <th>
              <FormattedMessage id="periodicSummarySetting.active" defaultMessage="Active" />
            </th>

            {!ReadOnly && (UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {periodicSummarySettingsData.map(n => {
            return (
              <Fragment key={n.PeriodicSummarySettingId}>
                <tr key={n.PeriodicSummarySettingId} data-unittest="periodicSummarySettingData">
                  <td>{n.Name}</td>
                  <td>{n.Code}</td>
                  <td>{n.Expression}</td>
                  <td>{n.IsActive}</td>
                  {!ReadOnly && (UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={fetchDataHandler.bind(this, n.PeriodicSummarySettingId)}
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="periodicSummarySettings.change" defaultMessage="Change" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={deleteDataHandler.bind(this, n.PeriodicSummarySettingId)}
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="periodicSummarySettings.delete" defaultMessage="Delete" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
                {periodicSummarySetting.showAddEditScreen && selectedPeriodicSummarySettingId === n.PeriodicSummarySettingId && editorMain}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass} ref={addNewRef}>
        {periodicSummarySetting.showAddEditScreen && selectedPeriodicSummarySettingId === 0 && editContent}
      </div>
    </div>
  );
};

PeriodicSummarySettingListTable.defaultProps = {
  periodicSummarySettingsData: []
};

PeriodicSummarySettingListTable.propTypes = {
  setEditHandler: PropTypes.func.isRequired,
  selectedPeriodicSummarySettingId: PropTypes.number,
  savePeriodicSummarySetting: PropTypes.func.isRequired,
  periodicSummarySetting: PropTypes.object.isRequired,
  describePeriodicSummarySetting: PropTypes.func.isRequired,
  changePeriodicSummarySetting: PropTypes.func.isRequired,
  periodicSummarySettingsData: PropTypes.array,
  UpdateAllowed: PropTypes.bool,
  disableMode: PropTypes.bool,
  disableModeHandler: PropTypes.func.isRequired
};

export default injectIntl(PeriodicSummarySettingListTable);
