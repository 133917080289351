import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import s from './UnitTagOut.module.scss';
import Button from 'react-bootstrap/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import locked from '../../assets/blue-locked.svg';
import unlocked from '../../assets/blue-unlocked.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedUnitTagOut, getSelectedUnitTagStatus, systemTagIn } from '../../selectors/index';
import { unitTagOutThunk } from '../../actions/unitManager';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

const TAGOUT_STATE = 'TAGOUT_STATE';
const CONFIRM_STATE = 'CONFIRM_STATE';
const TAGIN_STATE = 'TAGIN_STATE';
const START = 'START';
const TAGOUT = 'TAGOUT';

const UnitTagOut = ({ currentTimezone, userProfileWidgets, unitId, isLoading, flowSequences, intl }) => {
  const tagoutdetails = useSelector(state => getSelectedUnitTagOut(state));

  const isTaggedOut = useSelector(state => getSelectedUnitTagStatus(state));
  const [tagState, setSetTagState] = useState(isTaggedOut === 1 ? TAGIN_STATE : TAGOUT_STATE);
  const { unitSerialNumber } = useParams();
  let tagoutCommand = flowSequences.find(item => item.Name.toUpperCase() == TAGOUT);

  let tagInCommandId = systemTagIn;
  let tagoutCommandId = (tagoutCommand && tagoutCommand.SequenceCode) || null;

  useEffect(() => {
    setSetTagState(isTaggedOut === 1 ? TAGIN_STATE : TAGOUT_STATE);
  }, [isTaggedOut]);

  switch (tagState) {
    case TAGIN_STATE:
      return (
        <TagIn
          tagoutdetails={tagoutdetails}
          unitId={unitId}
          currentTimezone={currentTimezone}
          intl={intl}
          unitSerialNumber={unitSerialNumber}
          isLoading={isLoading}
          tagInCommandId={tagInCommandId}
          setSetTagState={setSetTagState}
        />
      );
    case CONFIRM_STATE:
      return (
        <TagConfirm
          commandId={tagoutCommandId}
          unitId={unitId}
          intl={intl}
          setSetTagState={setSetTagState}
          isLoading={isLoading}
          unitSerialNumber={unitSerialNumber}
        />
      );
    default:
      return <TagOut intl={intl} setSetTagState={setSetTagState} />;
  }
};

const TagOut = ({ setSetTagState, intl }) => {
  const tagOutButton = intl.formatMessage({ id: 'maintenance.tagout.tagOutUnit', defaultMessage: 'TAG OUT UNIT' });
  const onTagOutHandler = () => {
    setSetTagState(CONFIRM_STATE);
  };
  return (
    <div className={s.unitTagOut}>
      <Row>
        <Col sm={12}>
          <h6>
            <img src={unlocked} />{' '}
            <span className={s.tagInText}>
              <FormattedMessage id="maintenance.tagout.unitisUnlocked" defaultMessage="Unit is unlocked" />{' '}
            </span>
          </h6>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Button variant="primary" noValidate className={s.tagButton} data-unittest="btnInsert" onClick={onTagOutHandler}>
            {tagOutButton}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const TagConfirm = ({ unitId, setSetTagState, isLoading, unitSerialNumber, commandId, intl }) => {
  const [reason, setReason] = useState('');
  const [localformErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!reason || isRequired(reason, 1)) {
      formErrors.reason = intl.formatMessage({
        id: 'maintenance.tagout.reasonIsRequired',
        defaultMessage: 'Reason is required'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onTagOutHandler = () => {
    if (!validateInput()) {
      return;
    }

    let tagOut = {
      UnitTagOutId: 0,
      UnitSerialNumber: unitSerialNumber,
      TagOutStatus: true,
      UnitId: unitId,
      Description: reason,
      CommandId: commandId,
      UserId: Cookies.get(`userid-${envName}`) || 0
    };

    dispatch(unitTagOutThunk(tagOut, 'UNIT_TAGGING'));
  };

  const onCancelHandler = () => {
    setSetTagState(TAGOUT_STATE);
  };
  return (
    <div className={s.unitTagOut}>
      {isLoading && <LoadingSpinner />}
      <Form>
        <Row>
          <Col sm={12}>
            <Form.Group controlId="formTagout">
              <Form.Label>
                <FormattedMessage id="maintenance.tagout.reason" defaultMessage="Reason for Tagout" />
              </Form.Label>

              <Form.Control
                as="textarea"
                rows="2"
                name="Reason"
                onChange={e => setReason(e.target.value)}
                value={reason}
                className={`${s.textArea} ${localformErrors && localformErrors.reason ? s.formControlError : ''}`}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Button
              variant="outline-secondary"
              className={classNames(s.margin5, s.btnSaveChangesSmall)}
              onClick={onTagOutHandler}
              noValidate
              data-unittest="saveData"
            >
              <FormattedMessage id="maintenance.tagout.confirm" defaultMessage="CONFIRM" />
            </Button>
            <Button
              variant="outline-secondary"
              className={classNames(s.btnCancelSmall)}
              onClick={onCancelHandler}
              noValidate
              data-unittest="saveCancel"
            >
              <FormattedMessage id="maintenance.tagout.cancel" defaultMessage="CANCEL" />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const TagIn = ({ tagoutdetails, unitId, currentTimezone, isLoading, unitSerialNumber, tagInCommandId, intl }) => {
  const tagInButton = intl.formatMessage({ id: 'maintenance.tagout.tagInUnit', defaultMessage: 'TAG IN UNIT' });
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [commandId, setCommandId] = useState(0);
  const handleClose = () => setActive(false);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const handleConfirm = () => {
    let tagInDetails = {
      UnitTagOutId: tagoutdetails.UnitTagOutId,
      TagOutStatus: false,
      UnitSerialNumber: unitSerialNumber,
      UnitId: unitId,
      CommandId: tagInCommandId,
      UserId: Cookies.get(`userid-${envName}`) || 0
    };
    dispatch(unitTagOutThunk(tagInDetails, 'UNIT_TAGGING'));
    handleClose();
  };

  const dialogTitle = intl.formatMessage({
    id: 'maintenance.tagInConfirmation',
    defaultMessage: 'Are you sure you want to tag in this unit?'
  });

  const UnitTagInText = intl.formatMessage({
    id: 'maintenance.tagout.unitisLockedby',
    defaultMessage: 'Unit is locked by'
  });

  const TagDateTime = tagoutdetails.TagOutDateTime
    ? moment
        .unix(tagoutdetails.TagOutDateTime)
        .tz(currentTimezone)
        .format('DD-MMM-YYYY HH:mm:ss z')
    : '-';

  const onTagOutHandler = commandId => {
    setActive(true);
    setCommandId(commandId);
  };
  return (
    <div className={s.unitTagOut}>
      {isLoading && <LoadingSpinner />}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <Row>
        <Col sm={12}>
          <h6>
            <img src={locked} />{' '}
            <span className={s.tagInText}>
              {UnitTagInText} {tagoutdetails.TagOutByUser || unitSerialNumber} on {TagDateTime}{' '}
            </span>
          </h6>
        </Col>
        <Col sm={12}>
          <Form.Label className={s.tagoutReason}>{tagoutdetails.Description}</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Button variant="primary" noValidate className={s.tagButton} data-unittest="btnInsert" onClick={onTagOutHandler}>
            {tagInButton}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(UnitTagOut);
