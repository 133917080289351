import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  GET_ALL_MODEL_VERSION_DETAILS,
  MODEL_VERSION_IS_LOADING,
  UPDATE_MODEL_VERSION_USER_SAVE_STATUS,
  MODEL_VERSION_MANAGEMENT_ERROR,
  SET_MODEL_VERSION_CURRENT_PAGE,
  SET_MODEL_VERSION_PAGE_FILTER,
  SET_MODEL_VERSION_DETAILS,
  SET_MODEL_VERSION_DETAILS_DUPLICATE,
  SET_MODEL_VERSION_CHANGE,
  MODEL_VERSION_THRESHOLDS_MANAGEMENT_ERROR,
  GET_ALL_MODEL_VERSION_DETAILS_THRESHOLDS,
  GET_ALL_MODEL_VERSION_DETAILS_SEQUENCES_VERSION,
  MODEL_VERSION_THRESHOLDS_IS_LOADING,
  MODEL_VERSION_GLOBAL_SETTINGS_MANAGEMENT_ERROR,
  MODEL_VERSION_GLOBAL_SETTINGS_IS_LOADING,
  GET_ALL_MODEL_VERSION_GLOBAL_SETTINGS,
  SET_MODEL_VERSION_THRESHOLDS_DETAILS,
  SET_MODEL_VERSION_THRESHOLDS_CHANGE,
  UPDATE_MODEL_VERSION_THRESHOLDS_SHOW_SCREEN,
  UPDATE_MODEL_VERSION_THRESHOLDS_SAVE_STATUS,
  UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SAVE_STATUS,
  SET_MODEL_VERSION_GLOBAL_SETTINGS_DETAILS,
  SET_MODEL_VERSION_GLOBAL_SETTINGS_CHANGE,
  UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SHOW_SCREEN,
  MODEL_VERSION_UNITS_MANAGEMENT_ERROR,
  MODEL_VERSION_UNITS_IS_LOADING,
  UPDATE_MODEL_VERSION_UNITS_SAVE_STATUS,
  GET_ALL_MODEL_VERSION_UNITS,
  SET_MODEL_VERSION_UNITS_CHANGE,
  UPDATE_MODEL_VERSION_UNITS_SHOW_SCREEN,
  MODEL_VERSION_MODEL_COMPONENTS_POSITIONS_MODEL,
  MODEL_VERSION_SERVICE_PLANS_MANAGEMENT_ERROR,
  MODEL_VERSION_SERVICE_PLANS_IS_LOADING,
  GET_ALL_MODEL_VERSION_SERVICE_PLANS,
  SET_MODEL_VERSION_SERVICE_PLANS_DETAILS,
  SET_MODEL_VERSION_SERVICE_PLANS_CHANGE,
  UPDATE_MODEL_VERSION_SERVICE_PLANS_SAVE_STATUS,
  UPDATE_MODEL_VERSION_SERVICE_PLANS_SHOW_SCREEN,
  MODEL_VERSION_UNIT_GLOBAL_SETTINGS_MANAGEMENT_ERROR,
  MODEL_VERSION_UNIT_GLOBAL_SETTINGS_IS_LOADING,
  GET_ALL_MODEL_VERSION_UNIT_GLOBAL_SETTINGS,
  SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_DETAILS,
  SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_CHANGE,
  UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SAVE_STATUS,
  UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SHOW_SCREEN,
  UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SHOW_SCREEN,
  MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_MANAGEMENT_ERROR,
  MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_IS_LOADING,
  GET_ALL_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS,
  SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_DETAILS,
  SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_CHANGE,
  UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SAVE_STATUS
} from '../constants/index';

import {
  MODEL_MANAGEMENT,
  UPDATE_MODEL_VERSION,
  CREATE_MODEL_VERSION,
  CREATE_MODEL_VERSION_THRESHOLD,
  UPDATE_MODEL_VERSION_THRESHOLD,
  CREATE_MODEL_VERSION_GLOBAL_SETTING,
  UPDATE_MODEL_VERSION_GLOBAL_SETTING,
  DELETE_MODEL_VERSION_THRESHOLD,
  DELETE_MODEL_VERSION_GLOBAL_SETTING,
  UPDATE_MODEL_VERSION_UNITS,
  DELETE_MODEL_VERSION_SERVICE_PLAN,
  CREATE_MODEL_VERSION_SERVICE_PLAN,
  UPDATE_MODEL_VERSION_SERVICE_PLAN,
  UNITGLOBALSETTINGS_MANAGEMENT,
  CREATE_UNITGLOBALSETTINGS,
  UPDATE_UNITGLOBALSETTINGS,
  DELETE_UNITGLOBALSETTINGS,
  CREATE_PERIODICSUMMARYSETTINGS,
  UPDATE_PERIODICSUMMARYSETTINGS,
  DELETE_PERIODICSUMMARYSETTINGS
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

//Model Version Management
export const setModelVersionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setModelVersionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelVersionError(messageCode, false));
  }, 2500);
};

export const setModelVersionError = (messageCode, status) => {
  return {
    type: MODEL_VERSION_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getModelVersionsThunk = (modelId, offset, limit, filter, widgetCode, organisationId) => dispatch => {
  dispatch(getModelVersion(modelId, offset, limit, filter, widgetCode, organisationId));
};

export const setLoadingStatus = isLoading => ({
  type: MODEL_VERSION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getModelVersion = (modelId, offset, limit, filter, widgetCode, organisationId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion`,
    data: {
      ModelId: modelId,
      Offset: offset,
      Limit: limit,
      Filter: filter,
      OrganisationId: organisationId ? organisationId : null
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelVersionListThunk(retData),
    onFailure: err => setModelVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelVersionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersions = (retData && retData.modelVersions) || [];
  dispatch(setAllModelVersionList(messageCode, modelVersions));
};

export const setAllModelVersionList = (messageCode, modelVersions) => ({
  type: GET_ALL_MODEL_VERSION_DETAILS,
  payload: {
    data: {
      modelVersions: modelVersions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_MODEL_VERSION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_MODEL_VERSION_PAGE_FILTER,
  payload: { filter }
});

export const postModelVersionThunk = (modelversion, widgetCode) => dispatch => {
  let url = '/management/modelversion/save';
  let actionName = modelversion.ModelVersionId === 0 ? CREATE_MODEL_VERSION : UPDATE_MODEL_VERSION;
  let log = logEntry(MODEL_MANAGEMENT, actionName, modelversion);
  dispatch(saveModelVersion(modelversion, url, log, widgetCode));
};

export const saveModelVersion = (modelversion, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: message => setModelVersionStatusThunk(message),
    onFailure: err => setModelVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ModelVersion: modelversion,
      log: log
    },
    widgetcode: widgetCode
  });

export const setModelVersionStatusThunk = message => dispatch => {
  dispatch(setModelVersionStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelVersionStatus('', false));
  }, 2500);
};

export const setModelVersionStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_VERSION_USER_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const describeModelVersionThunk = (modelVersionId, isDuplicate, widgetCode) => dispatch => {
  dispatch(describeModelVersion(modelVersionId, isDuplicate, widgetCode));
};

export const describeModelVersion = (modelVersionId, isDuplicate, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/describe`,
    data: {
      ModelVersionId: modelVersionId || undefined,
      includeOrganisationHirarchy: isDuplicate ? true : false,
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => (isDuplicate ? setdescribeModelVersionDuplicateThunk(retData) : setdescribeModelVersionThunk(retData)),
    onFailure: err => setModelVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeModelVersionThunk = retData => dispatch => {
  dispatch(setDescribeModelVersion(retData));
};

export const setDescribeModelVersion = ({ retData }) => ({
  type: SET_MODEL_VERSION_DETAILS,
  payload: retData
});

export const setdescribeModelVersionDuplicateThunk = retData => dispatch => {
  dispatch(setDescribeModelVersionDuplicate(retData));
};

export const setDescribeModelVersionDuplicate = ({ retData }) => ({
  type: SET_MODEL_VERSION_DETAILS_DUPLICATE,
  payload: retData
});

export const setModelVersionChangeThunk = retData => dispatch => {
  dispatch(setModelVersionChange(retData));
};

export const setModelVersionChange = retData => ({
  type: SET_MODEL_VERSION_CHANGE,
  payload: retData
});

// Thresholds

export const setThresholdsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setThresholdError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setThresholdError(messageCode, false));
  }, 2500);
};

export const setThresholdError = (messageCode, status) => {
  return {
    type: MODEL_VERSION_THRESHOLDS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getThresholdsThunk = (offset, limit, filter, modelVersionId, widgetCode) => dispatch => {
  if (modelVersionId) {
    dispatch(getThesholds(offset, limit, filter, modelVersionId, widgetCode));
  }
};

export const setThresholdLoadingStatus = isLoading => ({
  type: MODEL_VERSION_THRESHOLDS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const setThresholdShowScreenStatusThunk = status => dispatch => {
  dispatch(setThresholdShowScreenStatus(status));
};

export const setThresholdShowScreenStatus = status => ({
  type: UPDATE_MODEL_VERSION_THRESHOLDS_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});

export const getThesholds = (offset, limit, filter, modelVersionId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/threshold`,
    data: {
      Offset: offset,
      Limit: limit,
      Filter: filter,
      ModelVersionId: modelVersionId
    },
    method: 'GET',
    onLoad: status => setThresholdLoadingStatus(status),
    onSuccess: retData => setAllThesholdListThunk(retData),
    onFailure: err => setThresholdsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllThesholdListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersionsThresholds = (retData && retData.modelVersionsThresholds) || [];
  dispatch(setAllThresholdList(messageCode, modelVersionsThresholds));
};

export const setAllThresholdList = (messageCode, modelVersionsThresholds) => ({
  type: GET_ALL_MODEL_VERSION_DETAILS_THRESHOLDS,
  payload: {
    data: {
      ThresholdsList: modelVersionsThresholds,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const describeThresholdThunk = (modelVersionsThresholdId, widgetCode) => dispatch => {
  dispatch(describeThreshold(modelVersionsThresholdId, widgetCode));
};

export const describeThreshold = (modelVersionsThresholdId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/threshold/describe`,
    data: {
      ModelVersionThresholdId: modelVersionsThresholdId || undefined
    },
    method: 'GET',
    onLoad: status => setThresholdLoadingStatus(status),
    onSuccess: retData => setdescribeThresholdThunk(retData),
    onFailure: err => setThresholdsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeThresholdThunk = retData => dispatch => {
  dispatch(setDescribeThreshold(retData));
};

export const setDescribeThreshold = ({ retData }) => ({
  type: SET_MODEL_VERSION_THRESHOLDS_DETAILS,
  payload: retData
});

export const setThresholdChangeThunk = retData => dispatch => {
  dispatch(setThresholdChange(retData));
};

export const setThresholdChange = retData => ({
  type: SET_MODEL_VERSION_THRESHOLDS_CHANGE,
  payload: retData
});

export const postThresholdThunk = (modelversionThreshold, widgetCode) => dispatch => {
  let url = '/management/modelversion/threshold/save';
  let actionName = modelversionThreshold.PerformDelete
    ? DELETE_MODEL_VERSION_THRESHOLD
    : modelversionThreshold.ModelVersionThresholdId === 0
    ? CREATE_MODEL_VERSION_THRESHOLD
    : UPDATE_MODEL_VERSION_THRESHOLD;

  let log = logEntry(MODEL_MANAGEMENT, actionName, modelversionThreshold);
  dispatch(saveThreshold(modelversionThreshold, url, log, widgetCode));
};

export const saveThreshold = (modelversionThreshold, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setThresholdLoadingStatus(status),
    onSuccess: retData => setThresholdStatusThunk(retData),
    onFailure: err => setThresholdsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      Threshold: modelversionThreshold,
      log: log
    },
    widgetcode: widgetCode
  });

export const setThresholdStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersionsThresholds = (retData && retData.modelVersionsThresholds) || [];

  dispatch(setThresholdStatusAndData(messageCode, modelVersionsThresholds, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setThresholdStatus('hhhh', false));
  }, 2500);
};

export const setThresholdStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_MODEL_VERSION_THRESHOLDS_SAVE_STATUS,
  payload: {
    data: {
      ThresholdsList: data,
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      showAddEditScreen: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setThresholdStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_VERSION_THRESHOLDS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

//Global Settings

export const setGlobalSetingsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setGlobalSetingsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setGlobalSetingsError(messageCode, false));
  }, 2500);
};

export const setGlobalSetingsError = (messageCode, status) => {
  return {
    type: MODEL_VERSION_GLOBAL_SETTINGS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getGlobalSetingsThunk = (offset, limit, filter, modelVersionId, widgetCode) => dispatch => {
  if (modelVersionId) {
    dispatch(getGlobalSetings(offset, limit, filter, modelVersionId, widgetCode));
  }
};

export const setGlobalSetingsLoadingStatus = isLoading => ({
  type: MODEL_VERSION_GLOBAL_SETTINGS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getGlobalSetings = (offset, limit, filter, modelVersionId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/globalsettings`,
    data: {
      Offset: offset,
      Limit: limit,
      Filter: filter,
      ModelVersionId: modelVersionId
    },
    method: 'GET',
    onLoad: status => setGlobalSetingsLoadingStatus(status),
    onSuccess: retData => setAllGlobalSetingsListThunk(retData),
    onFailure: err => setGlobalSetingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllGlobalSetingsListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersionGlobalSettings = (retData && retData.modelVersionGlobalSettings) || [];
  dispatch(setAllGlobalSetingsList(messageCode, modelVersionGlobalSettings));
};

export const setAllGlobalSetingsList = (messageCode, modelVersionGlobalSettings) => ({
  type: GET_ALL_MODEL_VERSION_GLOBAL_SETTINGS,
  payload: {
    data: {
      GlobalSettingsList: modelVersionGlobalSettings,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const describeGlobalSetingThunk = (modelVersionGlobalSettingId, widgetCode) => dispatch => {
  dispatch(describeGlobalSeting(modelVersionGlobalSettingId, widgetCode));
};

export const describeGlobalSeting = (modelVersionGlobalSettingId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/globalsettings/describe`,
    data: {
      ModelVersionGlobalSettingId: modelVersionGlobalSettingId || undefined
    },
    method: 'GET',
    onLoad: status => setGlobalSetingsLoadingStatus(status),
    onSuccess: retData => setdescribeGlobalSetingThunk(retData),
    onFailure: err => setGlobalSetingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeGlobalSetingThunk = retData => dispatch => {
  dispatch(setDescribeGlobalSeting(retData));
};

export const setDescribeGlobalSeting = ({ retData }) => ({
  type: SET_MODEL_VERSION_GLOBAL_SETTINGS_DETAILS,
  payload: retData
});

export const setGlobalSetingChangeThunk = retData => dispatch => {
  dispatch(setGlobalSetingChange(retData));
};

export const setGlobalSetingChange = retData => ({
  type: SET_MODEL_VERSION_GLOBAL_SETTINGS_CHANGE,
  payload: retData
});

export const postGlobalSetingThunk = (modelversionGlobalSetting, widgetCode) => dispatch => {
  let url = '/management/modelversion/globalsettings/save';
  let actionName = modelversionGlobalSetting.PerformDelete
    ? DELETE_MODEL_VERSION_GLOBAL_SETTING
    : modelversionGlobalSetting.ModelVersionGlobalSettingId === 0
    ? CREATE_MODEL_VERSION_GLOBAL_SETTING
    : UPDATE_MODEL_VERSION_GLOBAL_SETTING;
  let log = logEntry(MODEL_MANAGEMENT, actionName, modelversionGlobalSetting);
  dispatch(saveGlobalSeting(modelversionGlobalSetting, url, log, widgetCode));
};

export const saveGlobalSeting = (modelversionGlobalSetting, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setGlobalSetingsLoadingStatus(status),
    onSuccess: retData => setGlobalSetingStatusThunk(retData),
    onFailure: err => setGlobalSetingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      GlobalSetting: modelversionGlobalSetting,
      log: log
    },
    widgetcode: widgetCode
  });

export const setGlobalSetingStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersionGlobalSettings = (retData && retData.modelVersionGlobalSettings) || [];

  dispatch(setGlobalSetingStatusAndData(messageCode, modelVersionGlobalSettings, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setGlobalSetingStatus('hhhh', false));
  }, 2500);
};

export const setGlobalSetingStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SAVE_STATUS,
  payload: {
    data: {
      GlobalSettingsList: data,
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      showAddEditScreen: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setGlobalSetingStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setGlobalSetingShowScreenStatusThunk = status => dispatch => {
  dispatch(setGlobalSetingShowScreenStatus(status));
};

export const setGlobalSetingShowScreenStatus = status => ({
  type: UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});

// Model version Units

export const setModelVersionUnitsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setModelVersionUnitsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelVersionUnitsError(messageCode, false));
  }, 2500);
};

export const setModelVersionUnitsError = (messageCode, status) => {
  return {
    type: MODEL_VERSION_UNITS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setModelVersionUnitsLoadingStatus = isLoading => ({
  type: MODEL_VERSION_UNITS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const setModelVersionUnitsStatusThunk = UnitData => dispatch => {
  let messageCode = UnitData && UnitData.message;
  let data = (UnitData && UnitData.retData) || [];

  dispatch(setModelVersionUnitsStatusAndData(messageCode, data, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelVersionUnitsError('hhhh', false));
  }, 2500);
};

export const setModelVersionUnitsStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_MODEL_VERSION_UNITS_SAVE_STATUS,
  payload: {
    data: {
      ModelVersionUnitsList: data.AllocatedUnits,
      UnallocatedUnitsList: data.UnallocatedUnits,
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      showAddEditScreen: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setAllModelVersionUnitsListThunk = UnitData => dispatch => {
  let messageCode = UnitData && UnitData.message;
  let data = (UnitData && UnitData.UnitData) || [];
  dispatch(setAllModelVersionUnitsList(messageCode, data));
};

export const setAllModelVersionUnitsList = (messageCode, data) => ({
  type: GET_ALL_MODEL_VERSION_UNITS,
  payload: {
    data: {
      ModelVersionUnitsList: data.AllocatedUnits,
      UnallocatedUnitsList: data.UnallocatedUnits,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setModelVersionUnitsChangeThunk = UnitData => dispatch => {
  dispatch(setModelVersionUnitsChange(UnitData));
};

export const setModelVersionUnitsChange = UnitData => ({
  type: SET_MODEL_VERSION_UNITS_CHANGE,
  payload: UnitData
});

export const setModelVersionUnitsScreenStatusThunk = status => dispatch => {
  dispatch(setModelVersionUnitsScreenStatus(status));
};

export const setModelVersionUnitsScreenStatus = status => ({
  type: UPDATE_MODEL_VERSION_UNITS_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});

export const getModelVersionUnitsThunk = (item, widgetCode) => dispatch => {
  if (item.ModelVersionId) {
    dispatch(getModelVersionUnits(item, widgetCode));
  }
};

export const getModelVersionUnits = (item, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/units`,
    data: {
      Offset: item.offset,
      Limit: item.limit,
      Filter: item.filter,
      ModelVersionId: item.ModelVersionId,
      ModelId: item.ModelId,
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0
    },
    method: 'GET',
    onLoad: status => setModelVersionUnitsLoadingStatus(status),
    onSuccess: retData => setAllModelVersionUnitsListThunk(retData),
    onFailure: err => setModelVersionUnitsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const saveModelVersionUnitsThunk = (modelVersionUnits, widgetCode) => dispatch => {
  let url = '/management/modelversion/units/save';
  let actionName = UPDATE_MODEL_VERSION_UNITS;
  let log = logEntry(MODEL_MANAGEMENT, actionName, modelVersionUnits);

  modelVersionUnits.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`);
  modelVersionUnits.ProfileId = Cookies.get(`selectedprofileid-${envName}`);
  modelVersionUnits.UserId = Cookies.get(`userid-${envName}`);

  dispatch(saveModelVersionUnits(modelVersionUnits, url, log, widgetCode));
};

export const saveModelVersionUnits = (modelVersionUnits, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setModelVersionUnitsLoadingStatus(status),
    onSuccess: retData => setModelVersionUnitsStatusThunk(retData),
    onFailure: err => setModelVersionUnitsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ModelVersion: modelVersionUnits,
      log: log
    },
    widgetcode: widgetCode
  });

//Get Model Component Position by ModelId

export const getModelComponentPositionsThunk = (modelId, onlyVirtual, telemetryComponent, widgetCode) => dispatch => {
  if (modelId) {
    dispatch(getModelComponentPositions(modelId, onlyVirtual, telemetryComponent, widgetCode));
  }
};

export const getModelComponentPositions = (modelId, onlyVirtual, telemetryComponent, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/componentposition`,
    data: {
      ModelId: modelId,
      OnlyVirtual: onlyVirtual,
      Telemetry: telemetryComponent
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelComponentPositionsListThunk(retData),
    onFailure: err => setModelVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelComponentPositionsListThunk = ({ retData }) => dispatch => {
  let componentPositionDetails = (retData && retData.componentPositionDetails) || [];
  dispatch(setAllModelComponentPositionsList(componentPositionDetails));
};

export const setAllModelComponentPositionsList = componentPositionDetails => ({
  type: MODEL_VERSION_MODEL_COMPONENTS_POSITIONS_MODEL,
  payload: {
    data: {
      ModelComponentPositions: componentPositionDetails
    }
  }
});

//Service Plan

export const setServicePlansErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setServicePlansError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setServicePlansError(messageCode, false));
  }, 2500);
};

export const setServicePlansError = (messageCode, status) => {
  return {
    type: MODEL_VERSION_SERVICE_PLANS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getServicePlansThunk = (offset, limit, filter, modelVersionId, widgetCode) => dispatch => {
  if (modelVersionId) {
    dispatch(getServicePlans(offset, limit, filter, modelVersionId, widgetCode));
  }
};

export const setServicePlansLoadingStatus = isLoading => ({
  type: MODEL_VERSION_SERVICE_PLANS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getServicePlans = (offset, limit, filter, modelVersionId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/serviceplans`,
    data: {
      Offset: offset,
      Limit: limit,
      Filter: filter,
      ModelVersionId: modelVersionId
    },
    method: 'GET',
    onLoad: status => setServicePlansLoadingStatus(status),
    onSuccess: retData => setAllServicePlansListThunk(retData),
    onFailure: err => setServicePlansErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllServicePlansListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersionServicePlans = (retData && retData.modelVersionServicePlan) || [];
  dispatch(setAllServicePlansList(messageCode, modelVersionServicePlans));
};

export const setAllServicePlansList = (messageCode, modelVersionServicePlans) => ({
  type: GET_ALL_MODEL_VERSION_SERVICE_PLANS,
  payload: {
    data: {
      ServicePlansList: modelVersionServicePlans,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const describeServicePlanThunk = (modelVersionGlobalSettingId, widgetCode) => dispatch => {
  dispatch(describeServicePlan(modelVersionGlobalSettingId, widgetCode));
};

export const describeServicePlan = (modelVersionServicePlanId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/serviceplans/describe`,
    data: {
      ModelVersionServicePlanId: modelVersionServicePlanId || undefined
    },
    method: 'GET',
    onLoad: status => setServicePlansLoadingStatus(status),
    onSuccess: retData => setdescribeServicePlanThunk(retData),
    onFailure: err => setServicePlansErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeServicePlanThunk = retData => dispatch => {
  dispatch(setDescribeServicePlan(retData));
};

export const setDescribeServicePlan = ({ retData }) => ({
  type: SET_MODEL_VERSION_SERVICE_PLANS_DETAILS,
  payload: retData
});

export const setServicePlanChangeThunk = retData => dispatch => {
  dispatch(setServicePlanChange(retData));
};

export const setServicePlanChange = retData => ({
  type: SET_MODEL_VERSION_SERVICE_PLANS_CHANGE,
  payload: retData
});

export const postServicePlanThunk = (modelversionServicePlan, widgetCode, isApply) => dispatch => {
  let url = '/management/modelversion/serviceplans/save';
  let actionName = modelversionServicePlan.PerformDelete
    ? DELETE_MODEL_VERSION_SERVICE_PLAN
    : modelversionServicePlan.ModelVersionServicePlanId === 0
    ? CREATE_MODEL_VERSION_SERVICE_PLAN
    : UPDATE_MODEL_VERSION_SERVICE_PLAN;
  let log = logEntry(MODEL_MANAGEMENT, actionName, modelversionServicePlan);
  dispatch(saveServicePlan(modelversionServicePlan, url, log, widgetCode, isApply));
};

export const saveServicePlan = (modelversionServicePlan, url, log, widgetCode, isApply = false) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setServicePlansLoadingStatus(status),
    onSuccess: retData => (isApply ? setServicePlanStatusApplyThunk(retData) : setServicePlanStatusThunk(retData)),
    onFailure: err => setServicePlansErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ServicePlan: modelversionServicePlan,
      log: log
    },
    widgetcode: widgetCode
  });

export const setServicePlanStatusApplyThunk = retData => dispatch => {
  let messageCode = retData && retData.message;

  dispatch(setServicePlanStatus(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setServicePlanStatus('', false));
  }, 2500);
};

export const setServicePlanStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersionServicePlans = (retData && retData.modelVersionServicePlan) || [];

  dispatch(setServicePlanStatusAndData(messageCode, modelVersionServicePlans, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setServicePlanStatus('', false));
  }, 2500);
};

export const setServicePlanStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_MODEL_VERSION_SERVICE_PLANS_SAVE_STATUS,
  payload: {
    data: {
      ServicePlansList: data,
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      showAddEditScreen: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setServicePlanStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_VERSION_SERVICE_PLANS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setServicePlanShowScreenStatusThunk = status => dispatch => {
  dispatch(setServicePlanShowScreenStatus(status));
};

export const setServicePlanShowScreenStatus = status => ({
  type: UPDATE_MODEL_VERSION_SERVICE_PLANS_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});

//Unit global settings
//====================
export const setUnitGlobalSettingsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUnitGlobalSettingsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitGlobalSettingsError(messageCode, false));
  }, 2500);
};

export const setUnitGlobalSettingsError = (messageCode, status) => {
  return {
    type: MODEL_VERSION_UNIT_GLOBAL_SETTINGS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getUnitGlobalSettingsThunk = (offset, limit, filter, modelVersionId, widgetCode) => dispatch => {
  if (modelVersionId) {
    dispatch(getUnitGlobalSettings(offset, limit, filter, modelVersionId, widgetCode));
  }
};

export const setUnitGlobalSettingsLoadingStatus = isLoading => ({
  type: MODEL_VERSION_UNIT_GLOBAL_SETTINGS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getUnitGlobalSettings = (offset, limit, filter, modelVersionId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/unitglobalsettings`,
    data: {
      Offset: offset,
      Limit: limit,
      Filter: filter,
      ModelVersionId: modelVersionId
    },
    method: 'GET',
    onLoad: status => setUnitGlobalSettingsLoadingStatus(status),
    onSuccess: retData => setAllUnitGlobalSettingsListThunk(retData),
    onFailure: err => setUnitGlobalSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitGlobalSettingsListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let unitGlobalSettings = (retData && retData.unitGlobalSettings) || [];
  dispatch(setAllUnitGlobalSettingsList(messageCode, unitGlobalSettings));
};

export const setAllUnitGlobalSettingsList = (messageCode, unitGlobalSettings) => ({
  type: GET_ALL_MODEL_VERSION_UNIT_GLOBAL_SETTINGS,
  payload: {
    data: {
      UnitGlobalSettingsList: unitGlobalSettings,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const describeUnitGlobalSettingThunk = (unitGlobalSettingId, widgetCode) => dispatch => {
  dispatch(describeUnitGlobalSetting(unitGlobalSettingId, widgetCode));
};

export const describeUnitGlobalSetting = (unitGlobalSettingId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/unitglobalsettings`,
    data: {
      UnitGlobalSettingId: unitGlobalSettingId || undefined
    },
    method: 'GET',
    onLoad: status => setUnitGlobalSettingsLoadingStatus(status),
    onSuccess: retData => setdescribeUnitGlobalSettingThunk(retData),
    onFailure: err => setUnitGlobalSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeUnitGlobalSettingThunk = retData => dispatch => {
  dispatch(setDescribeUnitGlobalSetting(retData));
};

export const setDescribeUnitGlobalSetting = retData => ({
  type: SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_DETAILS,
  payload: retData
});

export const setUnitGlobalSettingChangeThunk = retData => dispatch => {
  dispatch(setUnitGlobalSettingChange(retData));
};

export const setUnitGlobalSettingChange = retData => ({
  type: SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_CHANGE,
  payload: retData
});

export const postUnitGlobalSettingThunk = (unitGlobalSetting, widgetCode) => dispatch => {
  let url = '/management/modelversion/unitglobalsettings/save';
  let actionName = unitGlobalSetting.MarkAsDeleted
    ? DELETE_UNITGLOBALSETTINGS
    : unitGlobalSetting.UnitGlobalSettingId === 0
    ? CREATE_UNITGLOBALSETTINGS
    : UPDATE_UNITGLOBALSETTINGS;
  let log = logEntry(UNITGLOBALSETTINGS_MANAGEMENT, actionName, unitGlobalSetting);
  dispatch(saveUnitGlobalSetting(unitGlobalSetting, url, log, widgetCode));
};

export const saveUnitGlobalSetting = (unitGlobalSetting, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setUnitGlobalSettingsLoadingStatus(status),
    onSuccess: retData => setUnitGlobalSettingStatusThunk(retData),
    onFailure: err => setUnitGlobalSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      unitGlobalSetting: { ...unitGlobalSetting },
      log: log
    },
    widgetcode: widgetCode
  });

export const setUnitGlobalSettingStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let unitGlobalSettings = (retData && retData.unitGlobalSettings) || [];

  dispatch(setUnitGlobalSettingStatusAndData(messageCode, unitGlobalSettings, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitGlobalSettingStatus('', false));
  }, 2500);
};

export const setUnitGlobalSettingStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SAVE_STATUS,
  payload: {
    data: {
      UnitGlobalSettingsList: data,
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      showAddEditScreen: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setUnitGlobalSettingStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setUnitGlobalSettingShowScreenStatusThunk = status => dispatch => {
  dispatch(setUnitGlobalSettingShowScreenStatus(status));
};

export const setUnitGlobalSettingShowScreenStatus = status => ({
  type: UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});

export const getSequenceVersionThunk = (modelVersionId, widgetCode) => dispatch => {
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(getSequenceVersion(widgetCode, organisationId, modelVersionId));
};

export const getSequenceVersion = (widgetCode, organisationId, modelVersionId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/sequenceversion`,
    data: {
      OrganisationId: organisationId,
      ModelVersionId: modelVersionId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllSequenceVersionListThunk(retData),
    onFailure: err => setServicePlansErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllSequenceVersionListThunk = data => dispatch => {
  let messageCode = data && data.message;
  let sequenceVersions = (data && data.retData && data.retData.SequenceVersions) || [];
  dispatch(setAllSequenceVersionList(messageCode, sequenceVersions));
};

export const setAllSequenceVersionList = (messageCode, sequenceVersions) => ({
  type: GET_ALL_MODEL_VERSION_DETAILS_SEQUENCES_VERSION,
  payload: {
    data: {
      SequenceVersions: sequenceVersions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

//Periodic Summary settings
//====================
export const setPeriodicSummarySettingsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setPeriodicSummarySettingsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setPeriodicSummarySettingsError(messageCode, false));
  }, 2500);
};

export const setPeriodicSummarySettingsError = (messageCode, status) => {
  return {
    type: MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getPeriodicSummarySettingsThunk = (offset, limit, filter, modelVersionId, widgetCode) => dispatch => {
  if (modelVersionId) {
    dispatch(getPeriodicSummarySettings(offset, limit, filter, modelVersionId, widgetCode));
  }
};

export const setPeriodicSummarySettingsLoadingStatus = isLoading => ({
  type: MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getPeriodicSummarySettings = (offset, limit, filter, modelVersionId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN1}/management/modelversion/periodicsummarysettings`,
    data: {
      Offset: offset,
      Limit: limit,
      Filter: filter,
      ModelVersionId: modelVersionId
    },
    method: 'GET',
    onLoad: status => setPeriodicSummarySettingsLoadingStatus(status),
    onSuccess: retData => setAllPeriodicSummarySettingsListThunk(retData),
    onFailure: err => setPeriodicSummarySettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllPeriodicSummarySettingsListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let periodicSummarySettings = (retData && retData.periodicSummarySettings) || [];
  dispatch(setAllPeriodicSummarySettingsList(messageCode, periodicSummarySettings));
};

export const setAllPeriodicSummarySettingsList = (messageCode, periodicSummarySettings) => ({
  type: GET_ALL_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS,
  payload: {
    data: {
      PeriodicSummarySettingsList: periodicSummarySettings,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const describePeriodicSummarySettingThunk = (periodicSummarySettingId, widgetCode) => dispatch => {
  dispatch(describePeriodicSummarySetting(periodicSummarySettingId, widgetCode));
};

export const describePeriodicSummarySetting = (periodicSummarySettingId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN1}/management/modelversion/periodicsummarysettings`,
    data: {
      PeriodicSummarySettingId: periodicSummarySettingId || undefined
    },
    method: 'GET',
    onLoad: status => setPeriodicSummarySettingsLoadingStatus(status),
    onSuccess: retData => setdescribePeriodicSummarySettingThunk(retData),
    onFailure: err => setPeriodicSummarySettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribePeriodicSummarySettingThunk = retData => dispatch => {
  dispatch(setDescribePeriodicSummarySetting(retData));
};

export const setDescribePeriodicSummarySetting = retData => ({
  type: SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_DETAILS,
  payload: retData
});

export const setPeriodicSummarySettingChangeThunk = retData => dispatch => {
  dispatch(setPeriodicSummarySettingChange(retData));
};

export const setPeriodicSummarySettingChange = retData => ({
  type: SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_CHANGE,
  payload: retData
});

export const postPeriodicSummarySettingThunk = (periodicSummarySetting, widgetCode) => dispatch => {
  let url = '/management/modelversion/periodicsummarysettings/save';
  let actionName = periodicSummarySetting.MarkAsDeleted
    ? DELETE_PERIODICSUMMARYSETTINGS
    : periodicSummarySetting.PeriodicSummarySettingId === 0
    ? CREATE_PERIODICSUMMARYSETTINGS
    : UPDATE_PERIODICSUMMARYSETTINGS;
  let log = logEntry(UNITGLOBALSETTINGS_MANAGEMENT, actionName, periodicSummarySetting);
  dispatch(savePeriodicSummarySetting(periodicSummarySetting, url, log, widgetCode));
};

export const savePeriodicSummarySetting = (periodicSummarySetting, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN1}${url}`,
    method: 'POST',
    onLoad: status => setPeriodicSummarySettingsLoadingStatus(status),
    onSuccess: retData => setPeriodicSummarySettingStatusThunk(retData),
    onFailure: err => setPeriodicSummarySettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      periodicSummarySetting: { ...periodicSummarySetting },
      log: log
    },
    widgetcode: widgetCode
  });

export const setPeriodicSummarySettingStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let periodicSummarySettings = (retData && retData.periodicSummarySettings) || [];

  dispatch(setPeriodicSummarySettingStatusAndData(messageCode, periodicSummarySettings, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setPeriodicSummarySettingStatus('', false));
  }, 2500);
};

export const setPeriodicSummarySettingStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SAVE_STATUS,
  payload: {
    data: {
      PeriodicSummarySettingsList: data,
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      showAddEditScreen: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setPeriodicSummarySettingStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setPeriodicSummarySettingShowScreenStatusThunk = status => dispatch => {
  dispatch(setPeriodicSummarySettingShowScreenStatus(status));
};

export const setPeriodicSummarySettingShowScreenStatus = status => ({
  type: UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});
