import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './UnitSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Banner from '../Banner/Banner';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import { getUserProfileWidget, getCurrentTimezone, getDailySummary } from '../../selectors/index';
import { sendCommand } from '../../actions/devices';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { COMMAND_MODE_SYSTEM, COMMAND_MODE_MANUAL, COMMAND_MODE_AUTO } from '../../constants';
import { getBannerStatus, getDeviceErrorStatus, getDeviceDisplayMessage, getDevicesState } from '../../selectors';

const UnitSettings = ({ intl }) => {
  const dispatch = useDispatch();
  const commandIdEnableLog = 100;
  const commandIdRebootFirmware = 120;
  const usbReset = 102;
  const firmwareUsbReset = 111;
  const selfService = 501;
  const emergencyStop = 101;

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const enableLogAllowed = IsWidgetAccessible(userProfileWidgets, 'IOTUNTISETTINGCOMMAND_100');
  const emergencyStopAllowed = IsWidgetAccessible(userProfileWidgets, 'IOTUNTISETTINGCOMMAND_101');
  const firmwareRebootAllowed = IsWidgetAccessible(userProfileWidgets, 'IOTUNTISETTINGCOMMAND_120');
  const selfServiceAllowed = IsWidgetAccessible(userProfileWidgets, 'IOTUNTISETTINGCOMMAND_501');
  const usbResetAllowed = IsWidgetAccessible(userProfileWidgets, 'IOTUNTISETTINGCOMMAND_102');
  const firmwareUsbResetAllowed = IsWidgetAccessible(userProfileWidgets, 'IOTUNTISETTINGCOMMAND_111');

  const errorStatus = useSelector(state => getDeviceErrorStatus(state));
  const displayMessage = useSelector(state => getDeviceDisplayMessage(state));
  const bannerStatus = useSelector(state => getBannerStatus(state));

  const [active, setActive] = useState(false);
  const [commandId, setCommandId] = useState(0);
  const [commandType, setCommandType] = useState(COMMAND_MODE_SYSTEM);
  const [commandValue, setCommandValue] = useState('');

  const handleClose = () => setActive(false);

  const handleCommand = (commandId, commandValue) => {
    setActive(true);
    setCommandId(commandId);
    if (commandId == selfService) {
      setCommandType(COMMAND_MODE_MANUAL);
    } else {
      setCommandType(COMMAND_MODE_AUTO);
    }
    setCommandValue(commandValue);
  };

  const handleConfirm = () => {
    dispatch(sendCommand(commandId, 'UNIT_SETTINGS', true, commandType, commandValue));
    handleClose();
  };

  const dialogTitle = intl.formatMessage({
    id: 'command.areyousureyouwanttoactionthecommand',
    defaultMessage: 'Are you sure you want to action the command?'
  });

  let messageId = displayMessage || '';
  let messageText = messageId != '' ? intl.formatMessage({ id: messageId, defaultMessage: messageId }) : '';
  messageText = bannerStatus ? messageText : '';

  return (
    <ContentTemplate
      selectedPage="unitSettings"
      userProfileWidgets={userProfileWidgets}
      widgetCode={'UNIT_SETTINGS'}
      tagoutRequired={false}
    >
      <div className={s.unitSettings}>
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
        <Banner failureText={messageText} showBanner={bannerStatus} status={errorStatus} successText={messageText} />
        <div className={s.contentWrapper}>
          <div className={s.unitSettingsContent}>
            <Form>
              {enableLogAllowed ? (
                <Row>
                  <Col lg={4}>
                    <div className={s.subHeading2}>
                      <FormattedMessage id="unitSettings.enableSerialLogs" defaultMessage="Enable Serial Logs" />
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className={s.margin15}>
                      <Button variant="primary" className={s.blue} onClick={() => handleCommand(commandIdEnableLog, 'true')} noValidate>
                        <FormattedMessage id="unitSettings.enable" defaultMessage="Enable" />
                      </Button>
                      <Button variant="primary" className={s.btnRed} onClick={() => handleCommand(commandIdEnableLog, 'false')} noValidate>
                        <FormattedMessage id="unitSettings.disable" defaultMessage="Disable" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {firmwareRebootAllowed ? (
                <Row>
                  <Col lg={4}>
                    <div className={s.subHeading2}>
                      <FormattedMessage id="unitSettings.rebootFirmware" defaultMessage="Reboot Firmware" />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={s.margin15}>
                      <Button
                        variant="primary"
                        className={s.btnRed}
                        onClick={() => handleCommand(commandIdRebootFirmware, 'Reboot')}
                        noValidate
                      >
                        <FormattedMessage id="unitSettings.reboot" defaultMessage="Reboot" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {firmwareUsbResetAllowed ? (
                <Row>
                  <Col lg={4}>
                    <div className={s.subHeading2}>
                      <FormattedMessage id="unitSettings.firmwareUsbReset" defaultMessage="Firmware Usb Reset" />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={s.margin15}>
                      <Button
                        variant="primary"
                        className={s.btnRed}
                        onClick={() => handleCommand(firmwareUsbReset, 'Firmware Usb Reset')}
                        noValidate
                      >
                        <FormattedMessage id="unitSettings.firmwareUsbReset" defaultMessage="Firmware Usb Reset" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {usbResetAllowed ? (
                <Row>
                  <Col lg={4}>
                    <div className={s.subHeading2}>
                      <FormattedMessage id="unitSettings.usbReset" defaultMessage="Usb Reset" />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={s.margin15}>
                      <Button variant="primary" className={s.btnRed} onClick={() => handleCommand(usbReset, 'Usb Reset')} noValidate>
                        <FormattedMessage id="unitSettings.usbReset" defaultMessage="Usb Reset" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}

              {emergencyStopAllowed ? (
                <Row>
                  <Col lg={4}>
                    <div className={s.subHeading2}>
                      <FormattedMessage id="unitSettings.emergencyStop" defaultMessage="Emergency Unit Stop" />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={s.margin15}>
                      <Button
                        variant="primary"
                        className={s.btnRed}
                        onClick={() => handleCommand(emergencyStop, 'Emergency Stop')}
                        noValidate
                      >
                        <FormattedMessage id="unitSettings.emergencyStop" defaultMessage="Emergency Unit Stop" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}

              {selfServiceAllowed ? (
                <Row>
                  <Col lg={4}>
                    <div className={s.subHeading2}>
                      <FormattedMessage id="unitSettings.maintenanceSelfService" defaultMessage="Maintenance (SelfService)" />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={s.margin15}>
                      <Button
                        variant="primary"
                        className={s.btnRed}
                        onClick={() => handleCommand(selfService, 'SelfService State')}
                        noValidate
                      >
                        <FormattedMessage id="unitSettings.maintenanceMode" defaultMessage="Maintenance" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </Form>
          </div>
        </div>
      </div>
    </ContentTemplate>
  );
};

export default injectIntl(UnitSettings);
