import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PeriodicSummarySettingsList from './PeriodicSummarySettingsList';
import { getModelVersionPeriodicSummarySettings } from '../../selectors/index';
import {
  getPeriodicSummarySettingsThunk,
  describePeriodicSummarySettingThunk,
  postPeriodicSummarySettingThunk,
  setPeriodicSummarySettingChangeThunk,
  setPeriodicSummarySettingShowScreenStatusThunk
} from '../../actions/modelVersion';
import { getCurrentTimezone } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    periodicSummarySetting: getModelVersionPeriodicSummarySettings(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPeriodicSummarySettings: (offset, limit, filter, modelVersionId) =>
        getPeriodicSummarySettingsThunk(offset, limit, filter, modelVersionId, 'PERIODICSUMMARYSETTINGS'),

      savePeriodicSummarySetting: (periodicSummarySetting, widgetCode) =>
        postPeriodicSummarySettingThunk(periodicSummarySetting, widgetCode),

      describePeriodicSummarySetting: periodicSummarySettingId =>
        describePeriodicSummarySettingThunk(periodicSummarySettingId, 'PERIODICSUMMARYSETTINGSEDIT'),

      changePeriodicSummarySetting: selectedPeriodicSummarySettings =>
        setPeriodicSummarySettingChangeThunk(selectedPeriodicSummarySettings),
      setPeriodicSummarySettingShowScreenStatus: status => setPeriodicSummarySettingShowScreenStatusThunk(status)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicSummarySettingsList);
